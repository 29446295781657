import Axios from "axios";
import auth from "./auth";

export const endpointRedirect = "http://api.chordscore.com";
// export const endpointRedirect = "http://127.0.0.1:8000";

export const endpoint = "https://api.chordscore.com/api";
// export const endpoint = "https://api-demo.chordscore.com/api";
// export const endpoint = "http://localhost:8000/api";

// export const endpointRedirect = "http://api-dev.chordscore.com";
// export const endpoint = "https://api-demo.chordscore.com/api";
// export const endpoint = "https://api-dev.chordscore.com/api";

export const api = Axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: `Bearer ${auth.getToken()}`,
    },
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.url === `${endpoint}/refresh/token`) {
            window.location.href = "/auth/error";
            return Promise.reject(error);
        }

        // if (localStorage.getItem("retry")) {
        //   localStorage.removeItem("retry");
        //   window.location.href = "/auth/error";
        //   return null;
        // }

        if (error.response.status === 401) {
            localStorage.setItem("retry", "true");
            const refreshToken = auth.getRefreshToken();
            return api
                .post("/refresh/token", {
                    refresh_token: refreshToken,
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.success) {
                            auth.setToken(res.data.data.access_token);
                            auth.setRefreshToken(res.data.data.refresh_token);
                            api.defaults.headers["Authorization"] =
                                res.data.data.access_token;
                            return window.location.reload();
                        } else {
                            window.location.href = "/auth/error";
                            return null;
                        }
                        // return window.location.reload();
                    } else {
                        window.location.href = "/auth/error";
                        return null;
                    }
                })
                .catch((err) => console.log(err));
        }

        if ((!localStorage.getItem("rememberMe") || localStorage.getItem("rememberMe") === "false") && error.response.status === 401) {
            window.location.href = "/auth/error";
            return null;
        }

        if(error.response.status === 500) {
            alert('Error 500: ')
            console.log(error);
        }

        if(error.response.status === 404) {
            alert('Error 404 not found ')
            console.log(error);
        }

        return Promise.reject(error);
    }
);
