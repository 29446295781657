import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  Root,
  Login,
  Register,
  FindPassword,
  Password,
  Setting,
  PolicyPrivacy,
  PolicyService,
  SupportSubmit,
  SupportPost,
  Notice,
  Chart,
  Search,
  Membership,
  Playlist,
  PlaylistInformation,
  Notes,
  KakaoLogin,
  Logout,
  AuthError,
  Profile,
  Recent,
  Main,
  FullSheetMusic,
} from '../pages';
import Support from '../pages/Support';
import PrivateRoute from '../components/PrivateRoute';
import SNSLogin from '../pages/SNSLogin';
import { SWRConfig } from 'swr';
import { api } from '../utils/api';
import Modal from '../components/Modal';
import NoteRoute from "../components/NoteRoute";

const App: React.FC = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const swrConfig = {
    fetcher(url: string, params: object) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  useEffect(() => {
    // if (/iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    //   /Android/i.test(navigator.userAgent)) {
    //   // confirm("모바일 환경에서는 어플에서만 이용가능합니다.\n스토어로 이동할까요?")
    //   setIsOpenConfirm(true);
    // }
    // if (/Android/i.test(navigator.userAgent)) {
    //   // confirm("모바일 환경에서는 어플에서만 이용가능합니다.\n스토어로 이동할까요?")
    //   setIsOpenConfirm(true);

    // }
  }, [])
  const userAgent = window.navigator.userAgent.toLowerCase();

  const gotoStore = () => {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=kr.chordscore.app'
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = 'https://apps.apple.com/kr/app/%EC%BD%94%EB%93%9C%EC%8A%A4%EC%BD%94%EC%96%B4-%EC%BD%94%EB%93%9C%EC%95%85%EB%B3%B4-%EC%97%B4%EB%9E%8C-%EC%84%9C%EB%B9%84%EC%8A%A4/id1550737595?l=en'
    }
  }

  const isAllowUserAgent = () => {
    return true;
    if (!/iPhone|iPad|iPod/i.test(navigator.userAgent) && !/Android/i.test(navigator.userAgent)) {
      return true;
    }
    alert(userAgent)
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const safari = /safari/.test(userAgent);

      if (safari) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  }

  return (
    <>
      {
        (isAllowUserAgent()) && (<SWRConfig value={swrConfig}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/old-main" component={Root} />
              <Route exact path="/login" component={SNSLogin} />
              <Route exact path="/chart" component={Chart} />
              <Route exact path="/recent" component={Recent} />

              <Route exact path="/auth/login" component={Login} />
              <Route exact path="/auth/logout" component={Logout} />
              <Route exact path="/auth/register" component={Register} />
              <Route exact path="/auth/find-password" component={FindPassword} />
              <Route exact path="/auth/password" component={Password} />
              <Route exact path="/auth/profile" component={Profile} />
              <Route exact path="/auth/kakao" component={KakaoLogin} />
              <Route exact path="/auth/error" component={AuthError} />

              <PrivateRoute exact path="/support" component={Support}/>
              <PrivateRoute
                exact
                path="/support/submit"
                component={SupportSubmit}
              />
              <PrivateRoute
                exact
                path="/support/:postIdx"
                component={SupportPost}
              />

              <PrivateRoute exact path="/membership" component={Membership}/>

              <Route exact path="/search/:searchValue" component={Search} />

              <Route exact path="/policy/service" component={PolicyService} />
              <Route exact path="/policy/privacy" component={PolicyPrivacy} />

              <PrivateRoute exact path="/notice" component={Notice}/>
              <PrivateRoute exact path="/setting" component={Setting}/>

              <PrivateRoute exact path="/playlist" component={Playlist}/>
              <PrivateRoute
                exact
                path="/playlist/:playlistIdx"
                component={PlaylistInformation}
              />

              <NoteRoute exact path="/notes/:songIdx" component={Notes}/>
              <Route exact path="/full-sheet-music" component={FullSheetMusic} />
            </Switch>
          </BrowserRouter>
        </SWRConfig>
        )
      }
      <Modal
        open={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        title="모바일 환경에서는 어플에서만 이용가능합니다."
        content="스토어로 이동할까요?"
        firstButtonLabel="확인"
        secondButtonLabel="취소"
        onClickFirstButton={gotoStore}
        onClickSecondButton={() => setIsOpenConfirm(false)}
      ></Modal>
    </>
  );
};

export default App;
