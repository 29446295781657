import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, NavLink, useParams } from 'react-router-dom';

import SideMenu from './SideMenu';
import { api, endpoint } from "../utils/api";
import Logo from '../assets/logo-chordscore.svg';
import LogoOnly from '../assets/logo-only.svg';
import AlertIcon from '../assets/alert-icon.svg';
import NaverIcon from '../assets/naver_cafe.png';
import YoutubeIcon from '../assets/youtube.png';
import SidebarIcon from '../assets/sidebar-icon.svg';
import BackWhiteIcon from '../assets/icon-back-white.png';

import auth from '../utils/auth';
import SearchBoxIcon from '../assets/searchbox-icon.svg';
import MobileSearchHeader from './MobileSearchHeader';
import useHiddenBodyOverflow from '../utils/useHiddenBodyOverflow';
import css from '@emotion/css';
import useWindowSize from '../utils/useWindowSize';
import historySearch from '../utils/historySearch';
import { format } from 'date-fns';
import Axios from 'axios';

interface IHeader {
  position: string;
}

const Header = ({ position }: IHeader) => {
  const history = useHistory();
  const params = useParams<{ searchValue: string }>();
  const [searchValue, setSearchValue] = useState('');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { width: screenWidth = 1980 } = useWindowSize();
  const isSmallScreen = screenWidth <= 500;
  const [displayHistorySearch, setDisplayHistorySearch] = useState(false);

  const onClickOpenSideMenu = () => setIsSideMenuOpen(true);
  const onClickCloseSideMenu = () => setIsSideMenuOpen(false);

  const fetchHistorySearch = async () => {
    if (auth.getToken()) {
      await Axios.get(
        `${endpoint}/history_search`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).then((res) => {
        historySearch.set(JSON.stringify(res.data));
      })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const saveHistorySearch = async () => {

    if (auth.getToken()) {
      await Axios.put(
        `${endpoint}/history_search`,
        { data: historySearch.getRaw() },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }).then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    fetchHistorySearch();
  }, []);

  useEffect(() => {
    if (params.searchValue) {
      setSearchValue(params.searchValue);
    }

    const searchInput = document.getElementById('search-input');
    searchInput?.addEventListener('focus', (event) => {
      setDisplayHistorySearch(true);
    }, true);
    searchInput?.addEventListener('blur', (event) => {
      setTimeout(() => {
        setDisplayHistorySearch(false);
      }, 200)
    }, true);

  }, [params.searchValue]);

  useHiddenBodyOverflow(isSearchOpen);

  const handleSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      history.push(`/search/${searchValue}`);
      historySearch.add(searchValue)
      await saveHistorySearch();
      setDisplayHistorySearch(false);
      // window.location.reload();
    }
  }

  const onClickHistorySearchItem = (name: string) => {
    history.push(`/search/${name}`);
    // window.location.reload();
  }

  const onClickOpenLink = () => {
    window.open('https://cafe.naver.com/chordscore', "_blank");
  }

  return (
    <>
      <Wrapper id="header" position={position}>
        <Container>
          <LeftWrap>
            {(isSmallScreen && history.location.pathname.includes('/notes')) && <BackIconWrap onClick={history.goBack}></BackIconWrap>}
            <LogoLink to="/">
              {!isSmallScreen ? <Logo /> : <LogoOnly />}
            </LogoLink>
            <MenuWrap>
              <Menu type="playlist" to="/playlist" text="연주목록" />
              <Menu type="chart" to="/full-sheet-music" text="전체악보" />
              {isSmallScreen && (
                <SearchIconWrap onClick={() => setIsSearchOpen(true)}>
                  악보검색
                </SearchIconWrap>
              )}
            </MenuWrap>
            <SearchBoxWrap>
              <SearchBox>
                <SearchBoxIconWrap>
                  <SearchBoxIcon />
                </SearchBoxIconWrap>
                <SearchBoxInput
                  id="search-input"
                  placeholder="검색어를 입력하세요."
                  autoComplete="off"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={(e) => { handleSearch(e) }}
                />
              </SearchBox>
              <DropdownHistorySearch display={displayHistorySearch}>
                {historySearch.get()?.map((item, index) => (
                  <HistorySearchItem key={index + '-history'} onClick={() => onClickHistorySearchItem(item.name)}>
                    <HistorySearchItemName>
                      {index + 1}. {item.name}
                    </HistorySearchItemName>
                    <HistorySearchItemDate>
                      {format(new Date(item.datetime), 'MM/dd')}
                    </HistorySearchItemDate>
                  </HistorySearchItem>
                ))}
              </DropdownHistorySearch>
            </SearchBoxWrap>
          </LeftWrap>
          <RightWrap>
            {!isSmallScreen && (
              <SearchIconWrap onClick={() => setIsSearchOpen(true)}>
                <SearchBoxIcon />
              </SearchIconWrap>
            )}

            {!isSmallScreen && (
                <RightIconWrap>
                  <NaverIconWrap onClick={() => window.open('https://cafe.naver.com/chordscore', "_blank")} />
                  <YoutubeIconWrap onClick={() => window.open('https://youtube.com/@chordscore5626', "_blank")} />
                </RightIconWrap>
            )}

            <Alert to="/notice" count={auth.getNoticeCount()} />
            <SidebarIconWrap onClick={onClickOpenSideMenu}>
              <SidebarIcon />
            </SidebarIconWrap>
          </RightWrap>
        </Container>
        {isSideMenuOpen && <SideMenu onClickClose={onClickCloseSideMenu} />}
      </Wrapper>
      {isSearchOpen && (
        <>
          <Background onClick={() => setIsSearchOpen(false)} />
          <MobileSearchHeader
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                setIsSearchOpen(!isSearchOpen);
                historySearch.add(searchValue);
                await saveHistorySearch();
                history.push(`/search/${searchValue}`);
                // window.location.reload();
              }
            }}
            onClickHistory={(name) => {
              history.push(`/search/${name}`);
              // window.location.reload();
            }}
            onClick={() => setIsSearchOpen(false)}
          />
        </>
      )}
    </>
  );
};

const Wrapper = styled.div<IHeader>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #59595926;
  width: 100%;
  background-color: white;
  z-index: 9999;

  html[color-mode='dark'] & {
    border-bottom: solid 1px #595959;
    background-color: #161920;
  }

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}
`;

const Container = styled.div`
  display: flex;
  padding: 24px 70px 23px 50px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1500px) {
    padding: 27px 0;
    max-width: 1200px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1300px) {
    max-width: unset;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    padding-top: 26px;
    padding-bottom: 27px;
  }

  @media screen and (max-width: 815px) {
    padding: 20px 0;
  }
`;

const LeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  height: fit-content;
  margin-right: 68px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1300px) {
    svg {
      width: 131px;
      height: 33px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 1030px) {
    margin-right: 19px;
  }

  @media screen and (max-width: 815px) {
    // margin-right: 0;

    svg {
      width: 70px;
      height: 33px;
    }
  }
`;

const MenuWrap = styled.div`
  display: flex;
  margin-right: 54px;

  @media screen and (max-width: 1300px) {
    margin-right: 0;
  }

  // @media screen and (max-width: 815px) {
  //   display: none;
  // }
`;

const RightWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Menu = ({ to, type, text }: { to: string; type: string; text: string }) => {
  const Icon = require(`../assets/menu-${type}-icon.svg`).default;
  return (
    <MenuContainer exact to={to} activeClassName="active_header_menu">
      <IconWrap>
        <Icon />
      </IconWrap>
      <MenuText>{text}</MenuText>
    </MenuContainer>
  );
};

const BackIconWrap = styled.div`
  height: 35px;
  width: 25px;
  background-image: url("${BackWhiteIcon}");
  background-size: cover;
`;

const MenuContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 50px;
  color: inherit;
  text-decoration: none;

  @media screen and (max-width: 1300px) {
    margin-right: 38px;
  }
`;

const IconWrap = styled.div`
  margin-right: 7px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  .active_header_menu & path {
    fill: #0a62ba !important;
  }

  // @media screen and (max-width: 960px) {
  //   display: none;
  // }
  @media screen and (max-width: 815px) {    
    svg {
      height: 30px;
      width: 30px;
    }
    display: none;
  }
`;

const MenuText = styled.span`
  font-size: 15px;
  font-weight: bold;
  line-height: 0.87;
  text-transform: uppercase;
  .active_header_menu & {
    color: #0a62ba;
  }

  @media screen and (max-width: 815px) {
    width: 30px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
  }
`;

const Alert = ({ count, ...props }: { count: string; to: string }) => (
  <AlertWrap {...props}>
    <AlertIcon />
    {parseInt(count || '0') > 0 && <AlertCount>{count}</AlertCount>}
  </AlertWrap>
);

const AlertWrap = styled(Link)`
  display: block;
  position: relative;
  margin-right: 30px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1030px) {
    margin-right: 25px;

    svg {
      width: 28px;
      height: 31px;
    }
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AlertCount = styled.span`
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #FF0000FF;

  font-size: 13px;
  font-weight: bold;
  line-height: 0.77;
  color: #ffffff;
`;

const RightIconWrap = styled.div`
  display: flex;
`

const NaverIconWrap = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 30px;
  background-image: url("${NaverIcon}");
  background-size: cover;

  html[color-mode='dark'] & path {
    //fill: white;
  }

  @media screen and (max-width: 1030px) {
    width: 34px;
    height: 34px;

    svg {
      width: 34px;
      height: 27px;
    }
  }
`;

const YoutubeIconWrap = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 30px;
  background-image: url("${YoutubeIcon}");
  background-size: cover;

  html[color-mode='dark'] & path {
    //fill: white;
  }

  @media screen and (max-width: 1030px) {
    width: 34px;
    height: 34px;

    svg {
      width: 34px;
      height: 27px;
    }
  }
`;

const SidebarIconWrap = styled.div`
  width: 37px;
  cursor: pointer;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1030px) {
    width: 34px;

    svg {
      width: 34px;
      height: 27px;
    }
  }
`;

export default Header;

const Background = styled.div`
  opacity: 0.91;
  background-color: #000000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
`;

const SearchIconWrap = styled.div`
  margin-right: 21px;
  svg {
    width: 33px;
    height: 33px;
    cursor: pointer;
    path {
      fill: white;
    }
  }
  @media screen and (min-width: 815px) {
    display: none;
  }
  @media screen and (max-width: 815px) {
    width: 30px;
  }
`;

export const SearchBoxWrap = styled.div`
position: relative;
display: inline-block;
`

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  width: 569px;
  height: 40px;
  border-radius: 22.5px;
  background-color: #d8d8d866;
  padding: 0 26px;

  html[color-mode='dark'] & {
    background-color: rgba(216, 216, 216, 0.11);
  }

  @media screen and (max-width: 1500px) {
    width: 309px;
  }

  @media screen and (max-width: 890px) {
    width: 250px;
  }

  @media screen and (max-width: 815px) {
    display: none;
  }
`;

export const SearchBoxIconWrap = styled.div`
  opacity: 0.8;
  margin-right: 11px;
  html[color-mode='dark'] & path {
    fill: #ffffff;
  }
`;

export const SearchBoxInput = styled.input`
  width: 100%;
  font-size: 15px;
  padding: 12px 0;
  border: 0;
  outline: 0;
  background: none;
  &::placeholder {
    color: #1e1e1e4d;
  }
  html[color-mode='dark'] & {
    opacity: 0.3;
    color: white;
    &::placeholder {
      color: white;
    }
  }
`;

const DropdownHistorySearch = styled.div<{ display: boolean }>`
  ${({ display }) =>
    display == true ? css`display: block;` : css`display: none;`
  }
  position: absolute;
  padding-top: 10px;
  background-color: #161920;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 100%;
`;

const HistorySearchItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  &:hover {
    background-color: #1c2230;
  }
`;

const HistorySearchItemName = styled.p`
`;

const HistorySearchItemDate = styled.p`
`;
