import React, {useEffect, useState} from 'react';
import {Route, RouteProps, useHistory} from 'react-router-dom';
import auth from '../utils/auth';
import Modal from './Modal';
import {api} from "../utils/api";
import InputWithLabel from "./InputWithLabel";
import Button from "./Button";
import styled from "@emotion/styled";

interface INoteRoute extends RouteProps {
    component: React.ComponentType<any>;
}

const NoteRoute = ({component: Component, ...rest}: INoteRoute) => {
    const history = useHistory();
    const [isSubs, setIsSubs] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [payStatus, setPayStatus] = useState<string>(auth.getPayStatus());
    const [isSendPhoneCert, setIsSendPhoneCert] = useState(false);
    const [phoneCertCount, setPhoneCertCount] = useState(1);
    const [info, setInfo] = useState({
        phone: "",
        phoneCert: "",
    });
    const [error, setError] = useState({
        phone: false,
    });

    const handleChange = (e: any) => {
        e.persist();
        setInfo((prevState) => ({...prevState, [e.target.name]: e.target.value}));
    };

    const onPhoneCert = async () => {
        if (phoneCertCount > 2) {
            alert('3회 이상 시도하셨습니다. 잠시 후 다시 시도해주세요.')
        }

        if (info.phone && !error.phone) {
            const {data} = await api.post("/sms/certification", {
                phone: info.phone,
            });

            if (data.success) {
                setPhoneCertCount(phoneCertCount + 1);
                setIsSendPhoneCert(true);
            }
        }
    }

    useEffect(() => {
        if (!auth.getToken()) {
            return;
        }

        const fetchData = async () => {
            const {
                data: {data},
            } = await api.get("/user");

            setIsLoading(false);
            auth.setPhone(data.user.phone);
            setPayStatus(data.user.pay_status.toString());
            auth.setPayStatus(data.user.pay_status.toString());
        };
        fetchData();
    }, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                auth.getToken() !== null ?
                    payStatus === '0' || payStatus === '1' || payStatus === '4' ?
                        <Component {...props} /> :
                        !isSubs ?
                            payStatus === '2' ?
                                <Modal
                                    open={true}
                                    onClose={() => history.goBack()}
                                    firstButtonLabel="확인"
                                    onClickFirstButton={() => history.push('/')}
                                    title="미결제 상태"
                                    content={
                                        <>
                                            구독신청 후 결제하지 않으셨습니다. <br/>
                                            카카오메시지 간편동의(페이소프트_업체명 에스비) 부탁 드립니다. <br/>
                                            결제 확인 후 악보열람 가능합니다. <br/>
                                            간편동의 완료 기준으로 3일 안으로 확인됩니다.
                                        </>
                                    }
                                />
                                :
                                <Modal
                                    open={true}
                                    onClose={() => history.goBack()}
                                    firstButtonLabel="구독신청"
                                    onClickFirstButton={async () => {
                                        if (isLoading) return;

                                        setIsSubs(true);

                                        if (auth.getPhone()) {
                                            const {data} = await api.post('/subscribe/add');
                                            auth.setPayStatus(data.data.pay_status);
                                        }
                                    }}
                                    secondButtonLabel="취소"
                                    onClickSecondButton={() => history.push('/')}
                                    title="구독 신청"
                                    content={
                                        <>
                                            유료 서비스입니다. <br/>
                                            월 3,300원으로 무제한 악보열람 하시겠습니까?
                                        </>
                                    }
                                />
                            :
                            auth.getPhone() ?
                                <Modal
                                    open={true}
                                    onClose={() => history.goBack()}
                                    firstButtonLabel="확인"
                                    onClickFirstButton={() => history.push('/')}
                                    title="구독 완료"
                                    content={
                                        <>
                                            구독 신청이 완료 되었습니다. <br/>
                                            지금부터 무제한 악보 열람이 가능합니다. <br/>
                                            카카오 메세지로 페이소프트(업체명:에스비) 간편동의 요청이 신청일 기준 2일 이내로 전송 됩니다. <br/>
                                            간편동의 해주시면 결제가 진행됩니다. <br/>
                                            간편동의 요청 접수 후 3일 내 결제가 이루어지지 않으면 구독 취소 됩니다. <br/>
                                            즐거운 연주 되세요.
                                        </>
                                    }
                                /> :
                                <Modal
                                    open={true}
                                    onClose={() => {
                                        history.goBack();
                                        setIsSubs(false);
                                    }}
                                    firstButtonLabel="확인"
                                    onClickFirstButton={async () => {
                                        const { data } = await api.post('/phone/change', {
                                            phone: info.phone,
                                            phoneCert: info.phoneCert
                                        });

                                        if (data.success) {
                                            auth.setPhone(info.phone);
                                            alert('변경 되었습니다.');
                                            history.push('/');
                                            setIsSubs(false);
                                        } else {
                                            alert(data.alert);
                                        }
                                    }}
                                    title="정보 변경"
                                    content={
                                        <InputWrap>
                                            구독 진행하기 위해서는 휴대폰 번호 등록이 필수 입니다.
                                            <br/>
                                            <br/>
                                            <InputWithLabel
                                                name="phone"
                                                label="휴대폰 번호"
                                                placeholder="휴대폰 번호를 입력해주세요."
                                                maxLength={11}
                                                onChange={handleChange}
                                                error={error.phone}
                                                errorMessage="올바르지 않은 휴대폰 형식"
                                            />
                                            {
                                                isSendPhoneCert &&
                                                <InputWithLabel
                                                    name="phoneCert"
                                                    label="인증 번호"
                                                    placeholder="인증 번호를 입력해주세요."
                                                    maxLength={10}
                                                    onChange={handleChange}
                                                />
                                            }
                                            <Button styleType="blue" onClick={onPhoneCert}>
                                                {
                                                    isSendPhoneCert ? '인증번호 재발송' : '휴대폰인증'
                                                }
                                            </Button>
                                        </InputWrap>
                                    }
                                />
                    :
                    <Modal
                        open={true}
                        onClose={() => history.goBack()}
                        firstButtonLabel="로그인"
                        onClickFirstButton={() => history.push('/auth/login')}
                        secondButtonLabel="회원가입"
                        onClickSecondButton={() => history.push('/auth/register')}
                        title="로그인 필요"
                        content={
                            <>
                                로그인 후 이용 가능합니다. <br/>
                            </>
                        }
                    />
            }
        />
    );
};

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 20px;
  }
`;

export default NoteRoute;
